<script setup lang="ts">
const customerLogos = [
  { src: '/images/customer-logos/Spyne.webp', alt: 'Spyne', width: '126', height: '32' },
  { src: '/images/customer-logos/AdIntelle.png', alt: 'AdIntelle', width: '126', height: '31' },
  { src: '/images/customer-logos/Taggshop.png', alt: 'Taggshop', width: '126', height: '126' },
  { src: '/images/customer-logos/Adrenalin.png', alt: 'adrenalin', width: '126', height: '66' },
  { src: '/images/customer-logos/Eklipse.png', alt: 'eklipse', width: '126', height: '46' },
  { src: '/images/customer-logos/PeopleStrong.png', alt: 'peoplestrong', width: '126', height: '21' },
  { src: '/images/customer-logos/Simplismart.png', alt: 'simplismart', width: '126', height: '42' },
  { src: '/images/customer-logos/Zenduty.png', alt: 'zenduty', width: '126', height: '46' },
  { src: '/images/customer-logos/Itilite.png', alt: 'itilite', width: '126', height: '126' },
  // { src: '/images/customer-logos/Hyperverge.png', alt: 'hyperverge', width: '126', height: '33' },
  { src: '/images/customer-logos/Phyllo.webp', alt: 'phyllo', width: '126', height: '126' },
  { src: '/images/customer-logos/BrandIntelle.svg', alt: 'brandintelle', width: '126', height: '20' },
  { src: '/images/customer-logos/JOP.svg', alt: 'jop', width: '100', height: '32' },
  { src: '/images/customer-logos/Zoconut.webp', alt: 'zoconut', width: '126', height: '17' },
  { src: '/images/customer-logos/Datoms.png', alt: 'Datoms', width: '126', height: '43' },
  { src: '/images/customer-logos/PracticebyNumbers.png', alt: 'practicebynumbers', width: '75', height: '75' },
  { src: '/images/customer-logos/Xebo.webp', alt: 'xebo', width: '126', height: '37' },
  { src: '/images/customer-logos/Survey2Connect.svg', alt: 'survey2connect', width: '126', height: '19' },
  { src: '/images/customer-logos/Tagbox.png', alt: 'tagbox', width: '126', height: '33' },
  // { src: '/images/customer-logos/Freightify.webp', alt: 'Freightify', width: '126', height: '24' },
  { src: '/images/customer-logos/Inven.png', alt: 'Inven', width: '125', height: '75' },
  { src: '/images/customer-logos/Astra.svg', alt: 'Astra', width: '80', height: '30' },
  { src: '/images/customer-logos/Nakad.png', alt: 'nakad', width: '126', height: '46' },
  { src: '/images/customer-logos/Algoshack.png', alt: 'algoshack', width: '126', height: '126' },
  { src: '/images/customer-logos/Zluri.png', alt: 'zluri', width: '100', height: '43' },
  { src: '/images/customer-logos/ExtraaEdge.webp', alt: 'extraaedge', width: '126', height: '50' },
  { src: '/images/customer-logos/Convin.svg', alt: 'convin', width: '126', height: '39' },
  { src: '/images/customer-logos/CareSmartz.png', alt: 'caresmartz', width: '126', height: '26' },
  { src: '/images/customer-logos/SingleInterface.png', alt: 'singleinterface', width: '126', height: '120' },
  { src: '/images/customer-logos/sheshi.svg', alt: 'sheshi', width: '100', height: '26' },
  { src: '/images/customer-logos/martech3D.webp', alt: 'martech3D', width: '100', height: '38' },
  { src: '/images/customer-logos/Milk-Moovement.webp', alt: 'Milk-Moovement', width: '118', height: '32' },
  { src: '/images/customer-logos/Nitro.webp', alt: 'Nitro', width: '118', height: '31' },
  // { src: '/images/customer-logos/Shiprocket.webp', alt: 'Shiprocket', width: '118', height: '24' },
  { src: '/images/customer-logos/CropinLogo.webp', alt: 'Cropin', width: '118', height: '46' },
  { src: '/images/customer-logos/FieldAssist.webp', alt: 'FieldAssist', width: '118', height: '31' },
  { src: '/images/customer-logos/Vervotech.webp', alt: 'Vervotech', width: '118', height: '34' },
];
</script>

<template>
  <div class="slider">
    <div class="slide-track gap-x-0 md:gap-x-8">
      <div
        class="slide flex h-[64px] w-[150px] items-center justify-center md:w-[200px]"
        v-for="image in [...customerLogos, ...customerLogos]"
        :key="image.alt"
        :id="image.alt"
      >
        <NuxtImg
          loading="lazy"
          :placeholder="[image.width, image.height]"
          :src="image.src"
          :alt="image.alt"
          :height="image.height"
          :width="image.width"
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
$animationSpeed: 40s;

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-200px * 24));
  }
}

.slider {
  height: 64px;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 100%;

  .slide-track {
    animation: scroll $animationSpeed linear infinite;
    display: flex;
    width: calc(200px * 48);

    @media only screen and (min-width: 1280px) {
      &:hover {
        animation-play-state: paused;
      }
    }
  }
}
</style>
